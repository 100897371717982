<template>
  <div class="page-wrapper">
    <div class="app-header d-flex justify-content-between align-items-center p-4">
      <div>
        <img src="/media/custom/orbi-horizontal-white.png" alt="" class="logo">
      </div>
      <div class="language-switcher d-flex">
        <div
          v-for="(item, idx) in languages"
          :key="item.lang"
          class="font-weight-boldest text-white cursor-pointer"
          :class="{'ml-5': idx, 'text-success': selectedLanguage === item.lang}"
          @click="changeLanguage(item.lang)"
        >
          {{ item.title }}
        </div>
      </div>
    </div>
    <router-view class="content-wrapper"></router-view>
    <app-footer class="footer-wrapper" mode="white"></app-footer>
  </div>
</template>

<script>
import i18nService from '@/core/services/i18n.service';
import LocalStorageService from '@/core/services/local-storage.service';
import AppFooter from '@/components/elements/custom/AppFooter';

export default {
  components: {
    AppFooter
  },
  data: () => ({
    languages: i18nService.languages,
    selectedLanguage: LocalStorageService.getLanguage() || 'en'
  }),
  methods: {
    changeLanguage(lang) {
      this.selectedLanguage = lang;
      this.$i18n.locale = lang;
      LocalStorageService.setLanguage(lang);
    }
  }
}
</script>

<style lang="scss" scoped>
.page-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  background-image: url('/media/bg/bg-orbi.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  .content-wrapper {
    display: flex;
    align-items: center;
    flex: 1;
  }

  .footer-wrapper {
    margin-top: auto;
  }
}

.app-header {
  height: 72px;
  border-bottom: 1px solid #FFFFFF;

  .logo {
    width: 200px;
  }
}
</style>